import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SearchDriverOptions } from '@elastic/search-ui';

const connector = new AppSearchAPIConnector({
  searchKey: 'search-nu9pqxuh6tyovmk7btoamc6z',
  engineName: process.env.COUNTRY_CODE === 'us' ? 'us' : 'products-engine',
  endpointBase: 'https://buywiselyau.ent.ap-southeast-2.aws.found.io',
});
const toRFC3339Date = (timestamp) => {
  return new Date(timestamp).toISOString();
};

export const config: SearchDriverOptions = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: connector,
  hasA11yNotifications: true,
  initialState: {
    resultsPerPage: 30,
  },
  searchQuery: {
    result_fields: {
      title: {
        snippet: {
          size: 100,
          fallback: true,
        },
      },
      slug: { raw: {} },
      image: { raw: {} },
      lowest_price: { raw: {} },
      highest_price: { raw: {} },
      discount: { raw: {} },
      offer_amount: { raw: {} },
      last_price_updated_at: { raw: {} },
      rating: { raw: {} },
      reviews_cnt: { raw: {} },
      seller_name: { raw: {} },
      sellers: { raw: {} },
      category_name: { raw: {} },
    },
    filters:
      process.env.COUNTRY_CODE === 'us'
        ? []
        : [
            {
              field: 'last_price_updated_at',
              values: [
                {
                  from: toRFC3339Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
                  name: 'Last 7 days',
                },
              ],
              type: 'all',
            },
          ],
    facets: {
      category_name: { type: 'value', size: 250 },
      sellers: { type: 'value' },
      rating: {
        type: 'range',
        ranges: [
          { from: 1, to: 2, name: '★☆☆☆☆' },
          { from: 2, to: 3, name: '★★☆☆☆' },
          { from: 3, to: 4, name: '★★★☆☆' },
          { from: 4, to: 5.1, name: '★★★★☆ & Up' },
        ],
      },
      last_price_updated_at: {
        type: 'range',
        ranges: [
          {
            from: toRFC3339Date(Date.now() - 24 * 60 * 60 * 1000),
            name: 'Last 24 hours',
          },
          {
            from: toRFC3339Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            name: 'Last 7 days',
          },
          {
            from: toRFC3339Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
            name: 'Last 30 days',
          },
          {
            from: toRFC3339Date(Date.now() - 2 * 30 * 24 * 60 * 60 * 1000),
            name: 'More than 1 month',
          },
        ],
      },
      lowest_price: {
        type: 'range',
        ranges: [
          { from: 0, to: 25, name: 'Under $25' },
          { from: 25, to: 50, name: '$25 to $50' },
          { from: 50, to: 100, name: '$50 to $100' },
          { from: 100, to: 200, name: '$100 to $200' },
          { from: 200, name: '$200 & Above' },
        ],
      },
    },
  },
  autocompleteQuery: {
    results: {
      resultsPerPage: 5,
      result_fields: {
        // specify the fields you want from the index to display the results
        image: { raw: {} },
        title: { snippet: { size: 100, fallback: true } },
        slug: { raw: {} },
        lowest_price: { raw: {} },
        highest_price: { raw: {} },
        sellers: { raw: {} },
      },
      search_fields: {
        // specify the fields you want to search on
        title: {},
      },
    },
  },
};

export const SORT_OPTIONS = [
  {
    name: 'Relevance',
    value: [],
  },
  {
    name: 'New - Old',
    value: [
      {
        field: 'last_price_updated_at',
        direction: 'desc',
      },
    ],
  },
  {
    name: 'Discount',
    value: [
      {
        field: 'discount',
        direction: 'asc',
      },
    ],
  },
  {
    name: 'Highest Rated',
    value: [
      {
        field: 'reviews_cnt',
        direction: 'desc',
      },
    ],
  },
  {
    name: 'Price Low to High',
    value: [
      {
        field: 'lowest_price',
        direction: 'asc',
      },
    ],
  },
  {
    name: 'Price High to Low',
    value: [
      {
        field: 'lowest_price',
        direction: 'desc',
      },
    ],
  },
];
